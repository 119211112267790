$main_color: rgba(64, 224, 208, 1);
$primary_color: #111111;
$primary_color_hover: #333333;
$secondary_color: #FF5400;
$secondary_color_hover: #ff5500e8;

$primary_color_transparent: #0E0E0ECC;

// $red: #DD4C50;

$white: #fff;
$black: rgba(44, 44, 44, 1);

$text_lite: #666E70;
$text_dark: #FFFFFF80;
$border_lite: #EDEFF6;
$row_lite: rgb(241, 241, 240);

$header_bg: #0E0E0E;

$light_blue: rgb(191, 209, 255);
$orange: rgb(255, 132, 19);
$orange_gradient: linear-gradient(180.00deg, rgb(255, 154, 36),rgb(255, 128, 36) 100%);
$blue_light: #0081F8;
$blue_light_hover: #2d99ff;
$dark_blue: rgba(0, 71, 64, 1);
$blue_gradient: linear-gradient(180.00deg, rgb(0, 23, 82),rgb(0, 18, 62) 100%);
$black_bg: #323232;
$green: rgba(48, 203, 67, 1);
$red: rgba(255, 0, 0, 1);
$text_color: rgb(35, 35, 35);

$btn_disabled: rgb(211, 211, 211);
$text_disabled: rgb(113, 113, 113);
$light_bg: rgba(238, 238, 238, 1);

$table_bg: rgba(239, 243, 255, 1);
$table-border: rgba(0, 0, 0, 0.2);


// P0016

$header_border: #A9AAAD;
$footer_bg: #000F2A;
$footer_border: #222222;
$footer_link: #BEBEC2;
$input_bg: #060F0F;
$body_bg: #F6F6F6;
$input_placeholder: #BBBBBB;
$swiper_bullets: #CFCFCF;
$swiper_bullets_dark: #6F6F6F;
$table_border: rgba(255, 255, 255, 0.24);
$border_lite_max: #E1E1E1;
$pagination_border: #E6E6E6;
$breadcrumbs_bg: #0000000A;
$lite_gray_bg: #ffffff10;
$dropdown_arrow: #172023;
$dots_gray: #D9D9D9;
$icon_bg: #ECECEC;
$banner_text: #FFFFFFCC;
$item_bg: #FAFAFA;
$lite_white: #FFFFFF1A;
$checkbox_border: #E1E3EA;
$table_header_bg: #F7F7F7;
$video_item_bg: #FDEEEB;
$video_time_bg: #F87959;
$progress_disable: #EBEBEB;
$table_text: #555555;
$table_subtext: #888888;
$table_hover: #FDFBEB;
$bottom_button_border: #FFFFFF38;
$bottom_button_bg: #FFFFFF0D;
$light_bullet: #DEDEE0;

$client_side_bg: #F4F4F4;
$lite_bg: #F5F5F5;

$contact_item_border: rgba(62, 62, 62, 1);
$contact_item_border_hover: rgba(255, 84, 0, 1);

$table_header_name: #A0A0A0;
$active_bg: #1BC95C;
$comment_color: #0A66C2;